import games_icon_white from "../../assets/icons/navigation/white/games-icon-basket.svg";
import games_icon_black from "../../assets/icons/navigation/black/games-icon-basket.svg";

import leaderboards_icon_white from "../../assets/icons/navigation/white/leaderboards.svg";
import leaderboards_icon_black from "../../assets/icons/navigation/black/leaderboards.svg";

import prizes_icon_white from "../../assets/icons/navigation/white/prizes.svg";
import prizes_icon_black from "../../assets/icons/navigation/black/prizes.svg";

import chat_icon_white from "../../assets/icons/navigation/white/chat.svg";
import chat_icon_black from "../../assets/icons/navigation/black/chat.svg";

import { thunk } from "easy-peasy";
import { action } from "easy-peasy";


const navigationModel = {
  hideControlBar: false,
  items: [
    {
      key: "games",
      url: "/",
      image: {
        white: games_icon_white,
        black: games_icon_black
      }
    },
    {
      key: "leaderboards",
      url: "/leaderboards",
      image: {
        white: leaderboards_icon_white,
        black: leaderboards_icon_black
      }
    },
    // {
    //   key: "prizes",
    //   url: "/prizes",
    //   image: {
    //     white: prizes_icon_white,
    //     black: prizes_icon_black
    //   }
    // },
    {
      key: "chat",
      url: "/chat",
      image: {
        white: chat_icon_white,
        black: chat_icon_black
      }
    },
  ],
  //ACTIONS
  setHideControlBar: action((state, hideControlBar) => {
    state.hideControlBar = hideControlBar;
  }),
};

export default navigationModel;
