import CorrectScore from "./CorrectScore";
import OddMultipleOptions from "./OddMultipleOptions";
import OddSelector from "./OddSelector";
import NoOdds from "./NoOdds";
import React from "react";

const DynamicOdds = ({ odds, betTemplate, team_one, team_two, userBets }) => {
  const PrintDynamicOdd = ({ oddTypeSelection }) => {
    const odd = odds?.find((odd) => odd.order === oddTypeSelection.order);
    switch (true) {
      case odd?.oddType?.externalBettingTypeId === 47:
        return (
          <OddMultipleOptions
            odd={odd}
            team_one={team_one}
            team_two={team_two}
            userBets={userBets}
          />
        );
      case odd?.oddType?.externalBettingTypeId === 45:
        return (
          <CorrectScore odd={odd} team_one={team_one} team_two={team_two} userBets={userBets} />
        );
      case odd?.oddOptions?.length >= 1 && odd?.oddOptions?.length < 4:
        return (
          <OddSelector odd={odd} team_one={team_one} team_two={team_two} userBets={userBets} />
        );
      
      case !odd || odd?.oddOptions.length === 0:
        return (
          <NoOdds
            name={`${oddTypeSelection?.bettingTypeName}, ${oddTypeSelection?.eventPartName}`}
          />
        );
      default:
        return;
    }
  };
  return (
    <>
      {betTemplate?.oddTypeSelection?.map((oddTypeSelection, index) => {
        return <PrintDynamicOdd key={index} oddTypeSelection={oddTypeSelection} />;
      })}
    </>
  );
};
export default React.memo(DynamicOdds);
