import React, { useEffect, useState } from "react";

import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { useStoreActions, useStoreState } from "easy-peasy";
import OverUnderModal from '../../OverUnderModal';

const OddMultipleOptions = ({ odd, team_one, team_two, userBets }) => {
  const { t } = useTranslation();
  const { setBets, resetBets } = useStoreActions((actions) => actions.matches);
  const { bets } = useStoreState((actions) => actions.matches);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const teams = [team_one, team_two];
  function getTeamNameById(id) {
    const team = teams.find((team) => team.id === id);
    return team ? team.name : false;
  }
  useEffect(() => {
    if (odd?.oddOptions?.length > 0) {
      setSelectOptions(
        odd?.oddOptions
          ?.map((item) => {
            return {
              ...item,
              points: item.value,
              team: item?.teamId,
              value: item._id,
              label: item.name,
            };
          })
          .sort((a, b) => (a.label !== b.label ? (a.label < b.label ? -1 : 1) : 0))
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [odd]);

  useEffect(() => {
    if (selectOptions?.length && Object.keys(bets).length) {
      if(bets[odd?.gameId] != undefined){
        setSelectedOption(selectOptions.find((item) => item.value === bets[odd?.gameId][odd?._id]));
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions, bets]);

  useEffect(() => {
    if (selectedOption){
      oddHandler(selectedOption);
    } else {
      // if(Object.keys(bets).length){
      //   const selectOptionValues = selectOptions.map(option => option.value);
      //   const betKey = Object.keys(bets)[0];
      //   const betObject = bets[betKey];
  
      //   const updatedBetObject = Object.keys(betObject)
      //       .filter(key => !selectOptionValues.includes(betObject[key]))
      //       .reduce((obj, key) => {
      //           obj[key] = betObject[key];
      //           return obj;
      //       }, {});
        
      //   const updatedBets =  { [betKey]: updatedBetObject };
      //   resetBets(updatedBets);
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  const oddHandler = (item) => {
    const payload = {
      gameId: odd?.gameId,
      oddId: odd?._id,
      oddOptionId: item?._id,
    };
    setBets(payload);
  };

  return (
    <>
      <div className={styles.odd_selector}>
        <h2>{t(`games.bets.${(odd?.name.replace(/[0-9]/g,"").replace(["."], ""))}`.replace(" ,", ","))}</h2>
        <div className={styles.odd_options}>
          {selectedOption ? (
            <div className={styles.selectedPlayer} onClick={() => setIsModalOpen(true)}>
              {selectedOption.label}
            </div>
          ) : (
            <button className={styles.selectButton} onClick={() => setIsModalOpen(true)}>
              {t('games.button.select_over-under')}
            </button>
          )}
          <p className={styles.value}>
            {
              selectedOption?.points ? 
              <>{selectedOption?.points.toFixed(2)} {t("app.general.pts")}</> : <>0.00 {t("app.general.pts")}</>
            }
            
          </p>
        </div>
      </div>
      {isModalOpen && (
        <OverUnderModal
          players={selectOptions}
          selectedPlayer={selectedOption}
          onSaveSelection={(player) => {
            setSelectedOption(player);
            setIsModalOpen(false);
          }}
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default OddMultipleOptions;
