import userModel from "./user.model";
import navigationModel from "./navigation.model";
import gameModel from "./game.model";
import leaderboardsModel from "./leaderboards.model";
import matchesModel from "./matches.model";
import chatModel from "./chat.model";

const model = {
    user: userModel,
    navigation: navigationModel,
    game: gameModel,
    leaderboards: leaderboardsModel,
    matches: matchesModel,
    chat: chatModel
};

export default model;