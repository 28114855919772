import React from "react";
import { Outlet } from "react-router-dom";
import style from "./index.module.scss";
import { useStoreState } from "easy-peasy";
import Navigation from "../Navigation";

const Layout = () => {
  const { hideControlBar } = useStoreState((store) => store.navigation);

  return (
    <>
      { !hideControlBar && <nav>
        <Navigation />
      </nav> }
      <main className={style.container}>
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
