import { action } from "easy-peasy";
import { thunk } from "easy-peasy";
import api, { ENDPOINTS } from "../../utils/axios";

const gameModel = {
  // DATA
  testMode: false,
  chalangeId: "",
  teams: [],

  //ACTIONS
  setTestMode: action((state, mode) => {
    state.testMode = mode;
  }),
  setChalangeID: action((state, id) => {
    state.chalangeId = id;
  }),

  //THUNKS
  getTestMode: thunk(async (actions, payload) => {
    actions.setTestMode(payload);
  }),
  getChalangeID: thunk(async (actions) => {
    try {
      const { data } = await api.get(ENDPOINTS.GAMES.CHALLENGES);
      if (data) {
        actions.setChalangeID(data._id);
      }
    } catch (error) {
      console.log(error);
    }
  }),
};
export default gameModel;
