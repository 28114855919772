import React, { useEffect, useState } from "react";

import styles from "./index.module.scss";

import firstPlaceIcon from "../../assets/icons/leaderboards/place_1.svg";
import secondThirdPlaceIcon from "../../assets/icons/leaderboards/place_2_3.svg";

import { useStoreActions, useStoreState } from "easy-peasy";

import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import classNames from "classnames";
import { Loader, Select } from "../../components";

const LeaderboardsPage = () => {
  const { t } = useTranslation();
  const { leaderboards, leaderboardsComplete, intervalsMonths, isLoading } =
    useStoreState((store) => store.leaderboards);
  const { chalangeId } = useStoreState((store) => store.game);
  const { getSelectData, getLeaderboardsUsers, setLeaderboardComplete } = useStoreActions(
    (actions) => actions.leaderboards
  );
  const { lang } = useStoreState((store) => store.user);

  // const selectPeriodOptions = [
  //   // {
  //   //   value: "overall",
  //   //   label: t("leaderboards.select.overall"),
  //   // },
  //   // {
  //   //   value: "weekly",
  //   //   label: t("leaderboards.select.weekly")
  //   // },
  //   {
  //     value: "monthly",
  //     label: t("leaderboards.select.monthly")
  //   }
  // ];

  // const [periodFilter, setPeriodFilter] = useState(selectPeriodOptions[0]);

  // const [weekFilter, setWeekFilter] = useState(intervalsWeeks?.[0]);
  const [monthlyFilter, setMonthlyFilter] = useState(intervalsMonths?.[0]);

  const [offset, setOffset] = useState(0);
  const limit = 20;

  const filteredIntervalsMonths = intervalsMonths?.filter(
    (month) => new Date(month.startDate) >= new Date("2024-11-01")
  );

  useEffect(() => {
    getSelectData(lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (periodFilter?.value === "weekly") {
  //     setWeekFilter(intervalsWeeks[0]);
  //   }
  //   if (periodFilter?.value === "monthly") {
  //     setMonthlyFilter(intervalsMonths[0]);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [periodFilter]);

  useEffect(() => {
    setOffset(0);
    setLeaderboardComplete(false);
    fetchData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyFilter, chalangeId]);

  // const fetchData = (newOffset) => {
  //   if (chalangeId) {
  //     switch (periodFilter?.value) {
  //       case "overall": {
  //         getLeaderboardsUsers({
  //           type: 0,
  //           challengeId: chalangeId,
  //           offset: newOffset,
  //           limit: limit,
  //           leaderboards: leaderboards
  //         });
  //         break;
  //       }
  //       case "weekly": {
  //         if (weekFilter) {
  //           getLeaderboardsUsers({
  //             challengeId: chalangeId,
  //             type: 1,
  //             startDate: weekFilter.startDate,
  //             endDate: weekFilter.endDate,
  //             offset: newOffset,
  //             limit: limit,
  //             leaderboards: leaderboards
  //           });
  //         }
  //         break;
  //       }
  //       case "monthly": {
  //         if (monthlyFilter) {
  //           getLeaderboardsUsers({
  //             challengeId: chalangeId,
  //             type: 3,
  //             startDate: monthlyFilter.startDate,
  //             endDate: monthlyFilter.endDate,
  //             offset: newOffset,
  //             limit: limit,
  //             leaderboards: leaderboards
  //           });
  //         }
  //         break;
  //       }
  //       default:
  //         break;
  //     }
  //   }
  // };

  useEffect(() => {
    if(intervalsMonths?.length > 0 && !monthlyFilter) {
      setMonthlyFilter(intervalsMonths[0]);
    }
  }, [intervalsMonths, monthlyFilter])

  const fetchData = (newOffset) => {
    if (chalangeId && monthlyFilter) {
      getLeaderboardsUsers({
        challengeId: chalangeId,
        type: 3,
        startDate: monthlyFilter.startDate,
        endDate: monthlyFilter.endDate,
        offset: newOffset,
        limit: limit,
        leaderboards: leaderboards,
      });
    }
  };

  const fetchdataHandler = () => {
    if (!leaderboardsComplete) {
      fetchData(offset + limit);
      setOffset((prev) => prev + limit);
    }
  };

  return (
    <div className={styles.leaderboards}>
      {/* <Select
        options={selectPeriodOptions}
        value={periodFilter}
        setValue={setPeriodFilter}
      />  */}
      {/* <div className={styles.filter}>
        {periodFilter.value === "weekly" && intervalsWeeks.length > 0 && weekFilter && (
          <Select
            options={intervalsWeeks}
            setValue={setWeekFilter}
            value={weekFilter}
          />
        )}
        {periodFilter.value === "monthly" && intervalsMonths.length > 0 && monthlyFilter && (
          <Select
            options={intervalsMonths}
            setValue={setMonthlyFilter}
            value={monthlyFilter}
          />
        )}
      </div> */}

      {/* Monthly Select Dropdown */}
      <div className={styles.filter}>
        {intervalsMonths.length > 0 && monthlyFilter && (
          <Select
            options={filteredIntervalsMonths}
            setValue={setMonthlyFilter}
            value={monthlyFilter}
          />
        )}
      </div>

      <InfiniteScroll
        dataLength={leaderboards.length}
        next={fetchdataHandler}
        hasMore={!leaderboardsComplete || leaderboards?.length % offset === 0}
        loader={<Loader />}
        className="container"
      >
        {leaderboards?.length > 0 ? (
          <ul className={styles.clasament_container}>
            {leaderboards.map((item, i) => (
              <li
                key={i}
                className={classNames(styles.clasament_item, {
                  [styles.first_places]: item.position <= 3,
                })}
              >
                {item.position === 1 ? (
                  <div className={styles.icon_container}>
                    <img
                      className={classNames(styles.icon, styles.first_place)}
                      src={firstPlaceIcon}
                      alt="1"
                    />
                  </div>
                ) : null}
                {item.position === 2 || item.position === 3 ? (
                  <img
                    className={styles.icon}
                    src={secondThirdPlaceIcon}
                    alt="2|3"
                  />
                ) : null}
                <span className={styles.position}>
                  {item.position === 0 ? "-" : item.position}
                </span>
                <span className={styles.name}>{t(item.name)}</span>
                <span className={styles.points}>
                  {item.points.toFixed(2)} <span>{t("app.general.pts")}</span>
                </span>
              </li>
            ))}
          </ul>
        ) : (
          !isLoading && (
            <div className="no-results">
              <div className="text">
                <h2>{t("app.general.no_results.title")}</h2>
                <p>{t("app.general.no_results.text")}</p>
              </div>
            </div>
          )
        )}
      </InfiniteScroll>
    </div>
  );
};

export default LeaderboardsPage;
