const urlParamReplacer = (url, ...params) => {
    if (typeof url !== 'string') {
        throw new Error("URL must be a string");
    }

    if (!params || params.length === 0) {
        throw new Error("Params must be provided");
    }

    const anyWordStartingWithColonRegex = /:\w+/; // Utilizare global regex

    params.forEach((param) => {
        if (typeof param !== 'string' && typeof param !== 'number') {
            throw new Error("Each param must be a string or number");
        }

        url = url.replace(anyWordStartingWithColonRegex, param);
    });

    return url;
};

export default urlParamReplacer;